
body {
	margin: 0;
}

.App {

	font-style: normal;
	font-weight: 700;
	color: white;
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	background-color: black;
}

a {
	user-select: none;
	color: inherit;
	text-decoration: none;
	font-size: 18px;
	font-weight: normal;
}

img {
	position: absolute;
	height: 100%;
}
